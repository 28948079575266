import axios from 'axios';

const backend = process.env.REACT_APP_BACKEND_URL;
const api = axios.create({
  baseURL: backend,
});

api.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      if(!error.response){
        alert("Server is down. Please notify our team and we will fix it immediately")
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        // Redirect to login
        alert("Unauthorized access. Please login before using our services");
        window.location.href = "/";
      }
      if (error.response.status === 404) {
        originalRequest._retry = true;
  
        alert("Unknown error occured. Please notify our team and we will fix it immediately. Error 404 ", error.response.data.message);
      }
      if (error.response.status === 422) {
        originalRequest._retry = true;
  
        alert("Unknown error occured. Please notify our team and we will fix it immediately. Error 422 ", error.response.data.message);
      }
      return Promise.reject(error);
    }
  );

export const getMyFiles = async (token) => {
  try {
    const response = await api.get('/myDocs', {
        headers: {
          'Authorization': 'Bearer '+token
        }
      });
    return response.data;
  } catch (error) {
    console.error('Error getting my docs:', error);
    throw error;
  }
};

export const downloadFileAPI = async (id,docType, token) => {
    try {
      const response = await api.get('/doc/'+id, {
        params: { type: docType }
      ,
          headers: {
            'Authorization': 'Bearer '+token
          },
          responseType: 'blob'
        });
      return response.data;
    } catch (error) {
      console.error('Error downloading my doc:', error);
      throw error;
    }
  };

  export const downloadAudioAPI = async (id, token) => {
    try {
      const response = await api.get('/audioByID/'+id, {
          headers: {
            'Authorization': 'Bearer '+token
          }
        }
    );
      return response.data;
    } catch (error) {
      console.error('Error downloading audio file:', error);
      throw error;
    }
  };

export const saveFileAPI = async (nameIn, textIn, token) => {
    try {
        const response = await api.post('/createWord', {
            name:nameIn,
            text:textIn
        }, 
        {
            headers: {
            'Authorization': 'Bearer '+token
            }
        }
        );
        return response.data;
    } catch (error) {
        console.error('Error saving file:', error);
        throw error;
    }
};

export const reqFormattingAPI = async (id, msg, token) => {
    try {
        const response = await api.post('/requestFormatting/'+id+"?msg="+msg, {}, 
        {
            headers: {
            'Authorization': 'Bearer '+token
            }
        }
        );
        return response.data;
    } catch (error) {
        console.error('Error saving file:', error);
        throw error;
    }
};

export const uploadFile = async (formData, token) => {
    try {
        const response = await api.post('/updateFile', formData, {
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'multipart/form-data',
            },
          });
        return response.data;
    } catch (error) {
        console.error('Error saving file:', error);
        throw error;
    }
};

export const submitFeedbackAPI = async (mistake, correct, language, token) => {
  mistake = String(mistake);
  mistake = mistake.replace("#", "%23");
  correct = String(correct);
  correct = correct.replace("#", "%23");
  console.log('/submitFeedback?mistake='+mistake+"&correct="+correct+"&language="+String(language));
  try{
    const response = await api.post('/submitFeedback?mistake='+String(mistake)+"&correct="+String(correct)+"&language="+String(language), {},{
      headers: {
          'Authorization': 'Bearer '+token,
          'Content-Type': 'multipart/form-data',
      },
    })
    return response.data;
  }
  catch(error){
    console.error('Error submitting feedback:', error);
    throw error;
  }
}

export const formatHeader = async (text, token) => {
  try {
    const response = await api.get('/formatHeader?inp='+text, {
        headers: {
          'Authorization': 'Bearer '+token
        }
      }
  );
    return response.data;
  } catch (error) {
    console.error('Error formatting header:', error);
    throw error;
  }
};

export const formatFooter = async (text, token) => {
  try {
    const response = await api.get('/formatFooter?inp='+text, {
        headers: {
          'Authorization': 'Bearer '+token
        }
      }
  );
    return response.data;
  } catch (error) {
    console.error('Error formatting footer:', error);
    throw error;
  }
};