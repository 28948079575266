import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {withAuthInfo} from '@propelauth/react';
import Home from './components/Home';
import MyFiles from './components/MyFiles';

function App({accessToken, isLoggedIn}) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/files" element={<MyFiles />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withAuthInfo(App);
