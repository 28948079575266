import React, { useState, useEffect } from 'react';
import Header from './Header'
import {withAuthInfo} from '@propelauth/react';
import Popup from 'reactjs-popup';
import { RingLoader } from 'react-spinners';
import { usePagination, useTable } from 'react-table';
import { getMyFiles, downloadFileAPI, downloadAudioAPI, reqFormattingAPI, uploadFile } from '../api';

import '../App.css';
import 'reactjs-popup/dist/index.css';

function MyFiles({accessToken, isLoggedIn}) {
  const [loading1, setloading1] = useState(false);
  const [path, setPath] = useState(null);
  const [downType, setDownType] = useState("pdf");
  const [popupOpen1, setPopupOpen1] = useState(false);
  const [formDet, setFormDet] = useState(null);
  const closeModal1 = () => setPopupOpen1(false);
  const [id, setId] = useState(null);
  const [popupOpen2, setPopupOpen2] = useState(false);
  const closeModal2 = () => setPopupOpen2(false);
  const [popupOpen3, setPopupOpen3] = useState(false);
  const closeModal3 = () => setPopupOpen3(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !id) {
      alert('Please select a file and enter a file ID.');
      return;
    }

    const formData = new FormData();
    formData.append('uploadedFile', file);
    formData.append('fileID', id);

    try {
      setloading1(true);
      const res = await uploadFile(formData, accessToken);
      alert('File uploaded successfully.');
      closeModal3();
      setloading1(false);
      window.location.reload();
    } catch (error) {
      alert('Error uploading1 file.');
    }
  };


  const handleOptionChange = (event) => {
    setDownType(event.target.value);
  };
  const downloadFile = async () => {
    setloading1(true);
    const response = await downloadFileAPI(path, downType, accessToken);
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `file.${downType}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setloading1(false);
    setPopupOpen1(false);
  }
  const formatFile = async () => {
    const res = await reqFormattingAPI(id, formDet, accessToken);
    if(res===true){
      alert('Requested formatting');
      window.location.reload();
    }
    setPopupOpen2(false);
  }
  const handleAudioDownload = async (speechId) => {
    const response = await downloadAudioAPI(speechId, accessToken);
    const link = document.createElement('a');
    link.href = response;
    link.setAttribute('download', "file.wav");
    document.body.appendChild(link);
    link.click();
  }
  const handleFormatChange = (event) => {
    setFormDet(event.target.value);
  }

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(
    [
      { Header: 'S.No.', accessor: 'sno' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Formatted', accessor: 'formatted'},
      { Header: 'Requested Formatting', accessor: 'requestFormat' ,         
      Cell: ({ row }) => (
        
          row.original.requestFormat==="No"?(
            <p>
          <button
            onClick={() => {
              setId(row.original.id);
              setPopupOpen2(true);
            }}
            className="sm text-blue-500 underline"
          >
            Format
          </button></p>):("Yes")
        
      ),},
      { Header: 'File', accessor: 'path' ,         
      Cell: ({ row }) => (<p>
        <button
          onClick={() => {
            setPath(row.original.id);
            setPopupOpen1(true);
          }}
          className="sm text-blue-500 underline"
        >
          Download
        </button></p>
      ),},
    ]
  );
  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 }  }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
  } = tableInstance;

  const fetchData = async (token) => {
    try {
      setloading1(true);
      const result = await getMyFiles(token);
      if(result.length!==0){
        if([result[0]].some(dict => dict.hasOwnProperty('username'))){
        setColumns(
          [
            { Header: 'S.No.', accessor: 'sno' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'User', accessor: 'username' },
            { Header: 'Request Formatting', accessor: 'requestFormat' ,         
            Cell: ({ row }) => (
              
                row.original.requestFormat==="No"?(
                  <p>
                  {/* {row.original.format} */}
                <button
                  onClick={() => {
                    setId(row.original.id);
                    setPopupOpen2(true);
                  }}
                  className="sm text-blue-500 underline"
                >
                  Request Now
                </button></p>):("Requested")
              
            ),},
            { Header: 'Formatted?', accessor: 'formatted' ,         
    Cell: ({ row }) => (
      
        row.original.formatted==="No"?(
          <p>
          {/* {row.original.format} */}
        <button
          onClick={() => {
            setId(row.original.id);
            setPopupOpen3(true);
          }}
          className="sm text-blue-500 underline"
        >
          Upload File
        </button></p>):("Yes")
      
    ),},
            { Header: 'File', accessor: 'path' ,         
            Cell: ({ row }) => (<p>
              <button
                onClick={() => {
                  setPath(row.original.id);
                  setPopupOpen1(true);
                }}
                className="sm text-blue-500 underline"
              >
                Download
              </button></p>
            ),},
            { Header: 'Audio', accessor: 'speechId' ,         
            Cell: ({ row }) => (
              <p><button
                onClick={() => {
                  handleAudioDownload(row.original.speechId);
                }}
                className="sm text-blue-500 underline"
              >
                Download
              </button></p>
            ),}
          ]
        )}
      }
      setData(result);
      setloading1(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };


  useEffect(() => {
    fetchData(accessToken);
  }, [accessToken]);

  return (
    <div className='bg-customWhite h-screen overflow-scroll'>
    <Header isLoggedIn={isLoggedIn}/>
    <div className="flex flex-col justify-center items-center mx-4 my-4">
    <table {...getTableProps()} className="w-full lg:max-w-3xl sm:max-w-">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}  className="px-1 py-2 border">{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}  className="px-1 py-2 border">{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-center space-x-2 sm:space-x-0 sm:flex-col sm:space-y-2 mt-4">
      {
          canPreviousPage?
          (<button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous Page
          </button>)
          :<></>
        }
        {
          canNextPage?
          (<button onClick={() => nextPage()} disabled={!canNextPage}>
            Next Page
          </button>)
          :<></>
        }
      </div>
      <Popup
  open={popupOpen1}
  closeOnDocumentClick
  onClose={closeModal1}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
  className="w-full sm:w-11/12 md:w-2/3 lg:w-1/2"

>
  <div className="flex flex-col justify-between">
    <p>Download file as</p>
    <label>
          <input
            type="radio"
            value="docx"
            checked={downType === 'docx'}
            onChange={handleOptionChange}
          />
          Word (.docx)
    </label>
    <label>
        <input
            type="radio"
            value="pdf"
            checked={downType === 'pdf'}
            onChange={handleOptionChange}
          />
          PDF (.pdf)
    </label>

  </div>
  <div className="flex justify-between mt-4">
      <button
        onClick={closeModal1}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Cancel
      </button>
      <button
        onClick={downloadFile}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Download
      </button>
    </div>
</Popup>
<Popup
  open={popupOpen2}
  closeOnDocumentClick
  onClose={closeModal2}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
  className="w-full sm:w-11/12 md:w-2/3 lg:w-1/2"

>
  <div className="flex flex-col justify-between">
    <p className='mb-4 text-center'>Format file</p>
    <p className='mb-4'>Please note formatting will take approx. 15 mins</p>
    <input
      type="text"
      className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
      placeholder="Enter formatting details"
      onChange={handleFormatChange}
    />
  </div>
  <div className="flex justify-between mt-4">
      <button
        onClick={closeModal2}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Cancel
      </button>
      <button
        onClick={formatFile}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Submit
      </button>
    </div>
</Popup>
<Popup
      open={popupOpen3}
      closeOnDocumentClick
      onClose={closeModal3}
      modal
      contentStyle={{
        maxWidth: '90%',
        width: 'auto',
        padding: '20px',
        borderRadius: '10px',
      }}
      className="w-full sm:w-11/12 md:w-2/3 lg:w-1/2"
    >
      <div className="flex flex-col justify-between">
        <p className='mb-4 text-center'>Upload File</p>
        <input
          type="file"
          className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
          onChange={handleFileChange}
        />
      </div>
      <div className="flex justify-between mt-4">
        <button
          onClick={closeModal3}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-5/12"
        >
          Cancel
        </button>
        <button
          onClick={handleUpload}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
        >
          Upload
        </button>
      </div>
    </Popup>
{loading1 ? (
        <Popup
          open={loading1}
          closeOnDocumentClick
          onClose={closeModal2}
          modal
          contentStyle={{
                            maxWidth: '90%',
                            width: 'auto',
                            padding: '20px',
                            borderRadius: '10px',
                        }}
        >
          <div className='flex justify-center items-center'>
          <RingLoader color="blue" loading1={loading1} />
          </div>
        </Popup>
      ):(<></>)
    }
    </div>

    </div>
  );
}

export default withAuthInfo(MyFiles);