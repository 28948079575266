import React, { useState, useRef, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import Header from './Header'
import {withAuthInfo} from '@propelauth/react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Popup from 'reactjs-popup';
import { RingLoader } from 'react-spinners';
import { saveFileAPI, submitFeedbackAPI, formatHeader, formatFooter } from '../api';
import '../App.css';
import '../index.css';
import 'reactjs-popup/dist/index.css';
import { useTable } from 'react-table';
import NoSleep from 'nosleep.js';
import { Editor } from '@tinymce/tinymce-react';


const editorConfiguration = {
  // toolbar: [ 'bold', 'italic', 'fontFamily' ],
  fontFamily: {
    options: [
        'default',
        'Ubuntu, Arial, sans-serif',
        'Ubuntu Mono, Courier New, Courier, monospace',
        'DevLys',
        'KrutiDev',
        'Mangal'
    ]
  },
};

function isOpen(ws) { return ws.readyState === ws.OPEN }

function Home({accessToken, isLoggedIn}) {
  const puncs = React.useMemo(
    () => [
        { term: "कोमा", symbol: "," },
        { term: "कोलन", symbol: ":" },
        { term: "स्लैश", symbol: "/" },
        { term: "ब्रैकेट स्टार्ट", symbol: "(" },
        { term: "ब्रैकेट क्लोज़", symbol: ")" },
        { term: "विराम or पूर्ढ़विराम", symbol: "।" },
        { term: "डबल कोट्स", symbol: "\"" }
    ],
    []
  );

const puncCols = React.useMemo(
    () => [
        {
            Header: 'Voice Command',
            accessor: 'term',
        },
        {
            Header: 'Symbol',
            accessor: 'symbol',
        },
    ],
    []
  );
  const tableInstance = useTable({ columns: puncCols, data: puncs })

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
  } = tableInstance


  const editorR = useRef(null);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [recognizedText, setRecognizedText] = useState('<p></p>');
  const [recognizingText, setRecognizingText] = useState('');
  const [editorInst, setEditorInst] = useState(null);
  const [lang, setLang] = useState('hindi');
  const [font, setFont] = useState('mangal');
  const [p1Open, setP1open] = useState(false);
  const [p2Open, setP2open] = useState(false);
  const [p3Open, setP3open] = useState(false);
  const [p4Open, setP4open] = useState(false);
  const [p5Open, setP5open] = useState(false);
  const [p6Open, setP6open] = useState(false);
  const [p7open, setP7open] = useState(false);
  const [currFile, setCurrFile] = useState(null);
  const [mistake, setMistake] = useState(null);
  const [correct, setCorrect] = useState(null);
  const closeModal1 = () => setP1open(false);
  const closeModal2 = () => setP2open(false);
  const closeModal3 = () => setP3open(false);
  const closeModal4 = () => setP4open(false);
  const closeModal5 = () => setP5open(false);
  const closeModal6 = () => setP6open(false);
  const closeModal7 = () => {
    setP7open(false);
    window.location.reload();
  };
  var noSleep = new NoSleep();
  const handleLangChange = (event) => {
    setLang(event.target.value);
  }
  const handleFontChange = (event) => {
    setFont(event.target.value);
  }
  const handleEditorInit = (evt, editor) => {
    setEditorInst(editor);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setRecognizedText(data);
  };
  const handleFileChange = (event) => {
    setCurrFile(event.target.value);
  };
  const handleMistake = (event) => {
    setMistake(event.target.value);
  };
  const handleCorrect = (event) => {
    setCorrect(event.target.value);
  };
  const submitFeedback = async () => {
    try {
      const responseData = await submitFeedbackAPI(mistake, correct, lang, accessToken)
      setP6open(false);
      setMistake(null);
      setCorrect(null);
      // console.log(responseData);
    } catch (error) {
      console.error('Error sending data:', error);
      setP6open(false);
      setMistake(null);
      setCorrect(null);
    }
  };
  function replaceLastP(str, replacement) {
    const lastIndex = str.lastIndexOf('</p>');
    if (lastIndex === -1) {
      return str; // Return the original string if </p> is not found
    }
    const partBeforeLastP = str.substring(0, lastIndex);
    const partAfterLastP = str.substring(lastIndex + '</p>'.length);
    return partBeforeLastP + replacement + partAfterLastP;
  }

  function replaceFirstP(str, replacement) {
    const index = str.indexOf('<p>');
    if (index === -1) {
      return str; // Return the original string if <p> is not found
    }
    const partBeforeFirstP = str.substring(0, index);
    const partAfterFirstP = str.substring(index + '<p>'.length);
    return partBeforeFirstP + replacement + partAfterFirstP;
  }
  function isValidHTML(str) {
    // Create a new DOMParser instance
    const parser = new DOMParser();
  
    // Parse the string as HTML
    const doc = parser.parseFromString(str, 'text/html');
  
    // Check for parser errors
    const hasParserErrors = doc.querySelectorAll('parsererror').length > 0;
    if (hasParserErrors) {
      return false;
    }
  
    // Check if there are any elements that are not automatically added by the browser
    const isNonEmpty = Array.from(doc.body.childNodes).some(node => {
      return node.nodeType === Node.ELEMENT_NODE && !['HTML', 'HEAD', 'BODY'].includes(node.nodeName);
    });
  
    return isNonEmpty;
  }
  
  function replaceAllTextWithHTML(htmlString, newHTML) {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    // Function to traverse and replace text in all nodes with new HTML
    function updateNodeWithHTML(node) {
        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
            // Create a temporary div to hold the new HTML
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = newHTML;
            // Replace the text node with new HTML
            while (tempDiv.firstChild) {
                node.parentNode.insertBefore(tempDiv.firstChild, node);
            }
            node.parentNode.removeChild(node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // If the element has text nodes as direct children, replace its innerHTML directly
            if (Array.from(node.childNodes).some(child => child.nodeType === Node.TEXT_NODE)) {
                node.innerHTML = newHTML;
            } else {
                // Recursively process child nodes
                Array.from(node.childNodes).forEach(updateNodeWithHTML);
            }
        }
    }
  
    // Start the process from the body of the parsed document
    updateNodeWithHTML(doc.body);
  
    // Serialize the modified DOM back into a string
    const serializer = new XMLSerializer();
    const modifiedHTMLString = serializer.serializeToString(doc.body);
  
    // Remove the extra <body> tags added by DOMParser
    return modifiedHTMLString.replace(/^<body>|<\/body>$/g, '');
}

  const insertHTML = (html) => {
    const splitter = 'नेक्स्ट';
    const commands = ['नेक्स्ट','डिलीट','अन्डू'];
    const containsAny = commands.some(element => html.includes(element));

    if(containsAny){
      if(html===splitter){
        cycleFocusById(editorInst);
      }
      else{
        var htmlList = html.split(splitter);
        console.log(htmlList);
        htmlList = htmlList.filter(element => ((element!=="&nbsp")));
        htmlList.forEach((part, index) => {
          if((isValidHTML(part) || (part===""))){
            insertHTML(part);
            if (index !== htmlList.length - 1) {
              cycleFocusById(editorInst);
            }
          }
          else{
            if(part==='डिलीट'){
              insertHTML('');
            }
            else if(part==='अन्डू'){
              editorInst.undoManager.undo();
            }
          }

        });
      }   
    }
    else{
      var curr = editorInst.selection.getContent();
      var range = editorInst.selection.getStart();
      if(isValidHTML(curr)){
        const updatedHTML = replaceAllTextWithHTML(curr, html);
        editorInst.undoManager.add({
          content: editorInst.selection.getContent(),
          bookmark: editorInst.selection.getRng()
        });
        editorInst.selection.setContent(updatedHTML);
        var element = editorInst.dom.select("#"+range.id)[0];
        var rng = document.createRange();
        rng.setStart(element, 0);
        rng.setEnd(element, 0);
        editorInst.selection.setRng(rng);    
      }
      else  {
        editorInst.undoManager.add({
          content: editorInst.selection.getContent(),
          bookmark: editorInst.selection.getRng()
        });
        editorInst.selection.setContent(html);
      }
    }
  }
  const scrollToBottom = () => {
    const editorContainer = document.querySelector('.ck-editor__editable');
    if (editorContainer) {
      editorContainer.scrollTop = editorContainer.scrollHeight;
    }
  };
  const handleFeedback = () => {
    setP6open(true);
    setP5open(false);
  }
    
  const websocketURL = process.env.REACT_APP_BACKEND_URL_WS;

  const startRecording = () => {
    noSleep.enable();
    const ws = new WebSocket(websocketURL);
    setLoading(true);
    ws.onopen = () => {
      console.log(isOpen(ws));
      ws.send(accessToken);
      ws.send(lang);
      ws.send(font);
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const audioRecorder = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm',
            timeSlice: 750,
            ondataavailable: blob => {
              console.log(isOpen(ws));
              // console.log(blob);
              if(isOpen(ws)){
                setLoading(false);
                ws.send(blob);
              }
            }
          });
          if(isOpen(ws)){
            setLoading(false);
            audioRecorder.startRecording();
            // console.log(audioRecorder);
            setRecorder(audioRecorder);  
          }
        }).catch(error => setP7open(true));
      if(isOpen(ws)){
        setLoading(false);
        setSocket(ws);
      }
      setLoading(false);
    };
    ws.onmessage = (event) => {
      if(event.data[0]==='0'){
        setRecognizingText(event.data.substring(1,event.data.length));
      }
      else if(event.data[0]==='1'){
          var newText = ' '+event.data.substring(1,event.data.length);
          const lineBreak = newText.includes('\n');
          const textLines = newText.split('\n');

          // Create a DOM parser to parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(recognizedText, 'text/html');
      
          // Find the last paragraph
          const paragraphs = doc.getElementsByTagName('p');
          const lastParagraph = paragraphs[paragraphs.length - 1];
      
          // Append new text to the last paragraph
          if (lastParagraph) {
            if(lineBreak){
              insertHTML(textLines[0]+"<br><br>");
            }
            else{
              const splitter = 'नेक्स्ट';
              if(textLines[0].trim()!==splitter){
                const parser = new DOMParser();
                const doc = parser.parseFromString(textLines[0], 'text/html');
                const spans = doc.querySelectorAll('span');
                spans.forEach((span) => {
                  if(span.innerHTML!=='नेक्स्ट'){
                    span.innerHTML += "&nbsp";
                  }
                });
                const serializedHtml = doc.body.innerHTML;
                insertHTML(serializedHtml);
              }
              else{
                insertHTML(textLines[0].trim());
              }
            }
          } else {
            // If there are no paragraphs, create a new one
            const newParagraph = doc.createElement('p');
            newParagraph.innerHTML = textLines[0];
            newParagraph.innerHTML += "&nbsp";
            insertHTML(newParagraph.outerHTML);
          }
          if (textLines.length > 1) {
            for (let i = 1; i < textLines.length-1; i++) {
              const newParagraph = doc.createElement('p');
              newParagraph.innerHTML = textLines[i]+"<br><br>";
              insertHTML(newParagraph.outerHTML);
            }
            const newParagraph = doc.createElement('p');
            newParagraph.innerHTML = textLines[textLines.length-1]+"&nbsp";
            insertHTML(newParagraph.outerHTML);
          }
          // Return the modified HTML as a string
          // return doc.body.innerHTML;
        // });
        
        // setRecognizedText(recognizedText => recognizedText+' '+event.data.substring(1,event.data.length));
        setRecognizingText('');
        scrollToBottom();
      }
      // else if(event.data==="Just friggin stop"){        
      //   console.log("Print closed ", socket)
      //   console.log(recorder)
      // }
      else{
        if(event.data==='401'){
          setP1open(true);
        }
      }
    };
    ws.onerror = (error) => {
      setLoading(false);
      setP2open(true);
    }
    ws.onclose = (event) => {
      setSocket(null)
    }
    

  };
  const saveFile = () => {
    if(currFile === null){
      setP3open(true);
    }
    else{
      // call API to save file
    }
  };

  // const formatSelect = async () => {
  //   if(editorInst !== null){
  //     var text = editorInst.dom.select('span[id="0_2"]');
  //     console.log(text)
  //     editorInst.selection.select(text);
  //     // var selection = editorInst.selection.getContent({ format: "text" });
  //     // setLoading(true);
  //     // var res = await formatHeader(selection, accessToken);
  //     // console.log(res);
  //     // insertHTML(res);
  //     // setLoading(false);
  //   }
  // }

  const changeLang = () => {
    setP5open(true);
  }

  const createFile = async (name) => {
    try {
      const responseData = await saveFileAPI(currFile, editorInst.getContent("myTextarea"), accessToken)
      setP3open(false);
      setP4open(true);
      setCurrFile(null);
      // console.log(responseData);
    } catch (error) {
      console.error('Error sending data:', error);
    }
    
    // call API to create file
    // if successful, setCurrFile(name)
  };
  const cycleFocusById = (editor) => {
    const idElements = Array.from(editor.getBody().querySelectorAll('[id]'));
    
    const sortedIdElements = idElements.sort((a, b) => {
      const positionA = editor.dom.getPos(a);
      const positionB = editor.dom.getPos(b);
      return positionA.y - positionB.y || positionA.x - positionB.x;
    });

    const selectionNode = editor.selection.getNode();
    const selectionPos = editor.dom.getPos(selectionNode);

    const nextIndex = sortedIdElements.findIndex((element, index) => {
      const elementPos = editor.dom.getPos(element);
      return selectionPos.y < elementPos.y || (selectionPos.y === elementPos.y && selectionPos.x < elementPos.x);
    });

    const nextElement = sortedIdElements[nextIndex !== -1 ? nextIndex : 0];
    if (nextElement) {
      editor.selection.select(nextElement);
      editor.selection.scrollIntoView(nextElement);
    }
  };
  const handleEditorInit2 = (editor) => {
    editor.on('keydown', (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        cycleFocusById(editor);
      }
    });
  };
  

  const stopRecording = () => {
    noSleep.disable();
    if (recorder) {
      recorder.stopRecording(() => {
        let blob = recorder.getBlob();
        socket.send(blob);

      });
      setRecorder(null);
    }
    const encoder = new TextEncoder();
    socket.send(encoder.encode('Recording Stopped'));

    // if (socket) {
    //   socket.close();
    //   setSocket(null);
    // }
  };
  useEffect(() => {
    return () => {
      if (editorR.current) {
        editorR.current.ui.view.toolbar.element.remove();
        editorR.current = null;
      }
    }
  }, []);
  return (
    <div className='bg-customWhite'>
    <Header isLoggedIn={isLoggedIn}/>
    <div className="app-container w-[75vw] mx-auto">
    <Editor
      apiKey='1kzcuanuhaw7gy8x6jrqx67o3qdtyxh2l0zxkq3ryw8or5zu'
      init={{
        setup: handleEditorInit2,
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker fullscreen code',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline | table align lineheight | numlist bullist indent outdent fullscreen code',
        font_family_formats:"Times New Roman=times new roman,times; Devlys=DevLys 010; KrutiDev=Kruti Dev 010; Mangal=Mangal; Arial=arial,helvetica,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;",
        content_css:"../App.css",
        content_style: "body { font-family: Arial; }",
        width:"100%",
        
      }}
      initialValue={recognizedText}
      onInit={handleEditorInit}
    />
    {/* <CKEditor
        onReady={ editor => {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
            editorR.current = editor;
            setEditorInst(editor);
            try{
              console.log(editorInst.model.document.selection.getFirstPosition());
            }
            catch{
              console.log("Instance not initialized");
            }
        } }
        onError={ ( error, { willEditorRestart } ) => {
            if ( willEditorRestart && editorR.current ) {
                editorR.current.ui.view.toolbar.element.remove();
            }
        }}
        editor={ DecoupledEditor }
        config={ editorConfiguration }
        data={recognizedText}
        onChange={handleEditorChange}
    /> */}
      <div className="status w-full">
      {recognizingText?
        <p className='recognizing'>{recognizingText}</p>: null}
      </div>
      <div className="controls w-full">
        {loading ? (
        <Popup
          open={loading}
          closeOnDocumentClick
          onClose={closeModal2}
          modal
          contentStyle={{
                            maxWidth: '90%',
                            width: 'auto',
                            padding: '20px',
                            borderRadius: '10px',
                        }}
        >
          <div className='flex justify-center items-center'>
          <RingLoader color="blue" loading={loading} />
          </div>
        </Popup>
      ):<>{((recorder===null)&(socket===null)) ? (<div className="flex justify-between">
            <button className="record-button w-4/12 mr-2" onClick={startRecording}>Start Recording</button>
            <button className="save-button w-4/12 mr-2" onClick={saveFile}>Save File</button>
            {/* <button className="save-button w-4/12" onClick={formatSelect}>Format</button> */}
            <button className="save-button w-4/12" onClick={changeLang}>Settings</button>
            </div>):<></>}
        <>{((socket!==null)&(recorder===null))?<div className='items-center'><p className='pt-1 text-center'>Please wait until all spoken text has been written...</p></div>:<></>}
        </>{(recorder!==null)?<div className='items-center'><button className="stop-button w-full" onClick={stopRecording}>Stop Recording</button></div>:<></>}
        
        </>}
      </div>
      
    </div>
    <Popup
  open={p1Open}
  closeOnDocumentClick
  onClose={closeModal1}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}

>
  <div className="text-center">
    <p>Please sign up or login first!</p>
    <button
      onClick={closeModal1}
      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-4"
    >
      Close
    </button>
  </div>
</Popup>
<Popup
  open={p2Open}
  closeOnDocumentClick
  onClose={closeModal2}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}

>
  <div className="text-center">
    <p>Server is down. Please contact us on hitesh.agarwal@diagnokare.in</p>
    <button
      onClick={closeModal2}
      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-4"
    >
      Close
    </button>
  </div>
</Popup>
<Popup
  open={p3Open}
  closeOnDocumentClick
  onClose={closeModal3}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
>
  <div className="text-center">
    <p className="mb-4">Please enter preferred file name</p>
    <input
      type="text"
      className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
      placeholder="File name"
      onChange={handleFileChange}
    />
    <div className="flex justify-between mt-4">
      <button
        onClick={closeModal3}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Cancel
      </button>
      <button
        onClick={createFile}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Save
      </button>
    </div>
  </div>
</Popup>
<Popup
  open={p4Open}
  closeOnDocumentClick
  onClose={closeModal4}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
>
  <div className="text-center">
    <p className="mb-4">File successfully saved</p>
      <button
        onClick={closeModal4}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
      >
        Close
      </button>
  </div>
</Popup>
<Popup
  open={p5Open}
  closeOnDocumentClick
  onClose={closeModal5}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
>
  <div className="text-center">
    <p className="mb-4">Please select language</p>
    <select value={lang} onChange={handleLangChange} className='w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline'>
        <option value="english">English</option>
        <option value="hindi">Hindi</option>
      </select>
      <p className="mb-4">Please select default font from speech to text</p>
    <select value={font} onChange={handleFontChange} className='w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline'>
        <option value="mangal">Mangal</option>
        <option value="kruti">Kruti Dev</option>
        <option value="dev">DevLys</option>
      </select>
      <div className="flex justify-between mt-4">
      <button
        onClick={handleFeedback}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Feedback
      </button>
      <button
        onClick={closeModal5}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Done
      </button>
    </div>
  </div>
</Popup>
<Popup
  open={p6Open}
  closeOnDocumentClick
  onClose={closeModal6}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
>
  <div className="text-center">
    <p className="mb-4">Mistaken word</p>
    <input
      type="text"
      className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
      placeholder="Word typed incorrectly"
      onChange={handleMistake}
    />
    <p className="mb-4">Correct word</p>
    <input
      type="text"
      className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
      placeholder="Correct transcription"
      onChange={handleCorrect}
    />
    <div className="flex justify-between mt-4">
      <button
        onClick={closeModal6}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Cancel
      </button>
      <button
        onClick={submitFeedback}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-5/12"
      >
        Submit
      </button>
    </div>
  </div>
</Popup>
<Popup
  open={p7open}
  closeOnDocumentClick
  onClose={closeModal7}
  modal
  contentStyle={{
    maxWidth: '90%',
    width: 'auto',
    padding: '20px',
    borderRadius: '10px',
  }}
>
  <div className="text-center">
    <p className="mb-4">Unable to detect your microphone. Please make sure (1) Microphone access is allowed in your browser settings (2) Your system is connected to a microphone. </p>
      <button
        onClick={closeModal7}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
      >
        Close
      </button>
  </div>
</Popup>

<div className='flex justify-between body text-center py-10 bg-gray-100'>
  <div className='pt-5 mx-auto'>
    <h2 className='text-2xl font-bold text-gray-800'>Guidelines for best results</h2>
    <div className='pt-3 px-2 flex justify-center'>
      <ol className='list-decimal list-inside bg-white shadow-lg rounded-lg p-5 max-w-md text-left'>
        <li className='mb-2'>Use a high-quality mic in silent, closed surroundings</li>
        <li className='mb-2'>When speaking on mic, you need to speak clearly and loudly</li>
        <li className='mb-2'>Do not speak too fast</li>
        <li className='mb-2'>Your pronunciation should be clear</li>
      </ol>
    </div>
  </div>
  <div className='pt-5 mx-auto'>
    <h2 className='text-2xl font-bold text-gray-800'>Guidelines for adding new line</h2>
    <div className='pt-3 px-2 flex justify-center'>
      <ol className='bg-white shadow-lg rounded-lg p-5 max-w-md text-left'>
        <p className='mb-2'>While typing a document in Hindi language, sometimes we have to add new lines and new paragraphs. Here we can simply add new lines or new paragraphs by using the voice command:</p>
        <h2 className='text-center pt-2'>न्यू पैरा</h2>
      </ol>
    </div>
  </div>
  <div className='pt-5 mx-auto'>
    <h2 className='text-2xl font-bold text-gray-800'>Guidelines for adding punctuations</h2>
    <div className='pt-3 px-2 flex justify-center'>
      <ol className='list-decimal list-inside bg-white shadow-lg rounded-lg p-5 max-w-md text-left'>
        <p className='mb-2'>While typing a document in Hindi language, sometimes we have to add punctuations like comma, colon, brackets, etc. Here we can simply add punctuations by using the following voice commands:</p>
        <div className='flex justify-center'>
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()} className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
      </ol>
    </div>
  </div>
</div>


    </div>
  );
}

export default withAuthInfo(Home);