import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../assets/logo.png"
import {useLogoutFunction, useRedirectFunctions} from '@propelauth/react';

function Header({isLoggedIn}) {
  const logoutFn = useLogoutFunction()
  const logout = async () => {
    await logoutFn()
    window.location.reload()
  }
  const {redirectToSignupPage, redirectToLoginPage, redirectToAccountPage} = useRedirectFunctions()
  return (
    <Navbar expand="lg" className="bg-transparent absolute inset-x-0 top-0">
      <Container className='p-3 max-w-[70%] text-lg font-bold'>
        <Navbar.Brand href="/" className='px-5'>
            <img
              src={logo}
              width="200"
              height="150"
              className="d-inline-block align-top"
              alt="Diagnokare Logo"
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='justify-end gap-4'/>
        <Navbar.Collapse id="basic-navbar-nav" className='justify-end gap-4'>
          <Nav className="justify-end gap-8">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/files">Files</Nav.Link>
            {isLoggedIn===true && 
              <Nav.Link href="#" onClick={redirectToAccountPage}> 
                Account 
              </Nav.Link>
            }

          {isLoggedIn===true &&
            <Nav.Link href="#" onClick={logout}>
              Logout <span aria-hidden="true">&rarr;</span>
            </Nav.Link>
          }

          {isLoggedIn===false && 
            <Nav.Link href="#" onClick={redirectToSignupPage}>
              Signup
            </Nav.Link>
          }

          {isLoggedIn===false && 
            <Nav.Link href="#" onClick={redirectToLoginPage} >
              Login <span aria-hidden="true">&rarr;</span>
            </Nav.Link>
          }
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;